import { useReferral } from '@features/referral/useReferral'
import type { Referral } from '@purposity/utils'
import React, {
  Dispatch,
  createContext,
  useContext,
  useMemo,
  useReducer,
} from 'react'
import { usePathname } from 'solito/navigation'
import type * as z from 'zod'
import { useAuthContext } from '~/features/auth/components/AuthProvider'

type RoadblockState = {
  modal: boolean
  lastPayload?: BlockActionPayload
}

export const enum RoadblockActionTypes {
  block = 'modal/open',
  hide = 'modal/hide',
}

export const createHideAction = (): RoadblockHideAction => ({
  type: RoadblockActionTypes.hide,
  payload: null,
})

const createBlockAction = ({
  referral,
  path,
}: {
  referral: z.infer<typeof Referral>
  path: string
}): RoadblockBlockAction => ({
  type: RoadblockActionTypes.block,
  payload: {
    referral,
    path,
  },
})

interface BlockActionPayload {
  referral?: z.infer<typeof Referral>
  path?: string
}

type RoadblockAction = RoadblockHideAction | RoadblockBlockAction

type RoadblockHideAction = {
  type: RoadblockActionTypes.hide
  payload: null
}

type RoadblockBlockAction = {
  type: RoadblockActionTypes.block
  payload: BlockActionPayload
}

interface IRoadblockContext {
  state: RoadblockState
  dispatch: Dispatch<RoadblockAction>
}

const RoadblockContext = createContext<IRoadblockContext | undefined>(undefined)

function roadblockReducer(state: RoadblockState, action: RoadblockAction) {
  switch (action.type) {
    case RoadblockActionTypes.block: {
      return { ...state, modal: true, lastPayload: action.payload }
    }
    case RoadblockActionTypes.hide: {
      return { ...state, modal: false }
    }

    default:
      throw new Error(`Unhandled action type`)
  }
}

function initializer(initialState: RoadblockState) {
  return initialState
}

export function RoadblockProvider({
  initializerArg = { modal: false },
  children,
}: {
  initializerArg?: RoadblockState
  children: React.ReactNode
}) {
  const [state, dispatch] = useReducer(
    roadblockReducer,
    initializerArg,
    initializer
  )

  const value = useMemo(
    () => ({
      state,
      dispatch,
    }),
    [state, dispatch]
  )
  return (
    <RoadblockContext.Provider value={value}>
      {children}
    </RoadblockContext.Provider>
  )
}

export function useRoadblock() {
  const context = useContext(RoadblockContext)
  if (context === undefined) {
    throw new Error('useRoadblock must be used within a RoadblockProvider')
  }
  return context
}

type RoadblockActionConfig = {
  trigger: () => void
  referral: z.infer<typeof Referral>
}

export function useRoadblockAction({
  trigger,
  referral,
}: RoadblockActionConfig) {
  const { isSignedIn } = useAuthContext()
  const roadblock = useRoadblock()
  const pathname = usePathname()
  const { setReferral } = useReferral()

  return {
    callback: () => {
      if (isSignedIn) {
        trigger()
      } else {
        setReferral(referral)
        roadblock.dispatch(
          createBlockAction({
            referral,
            path: pathname || '',
          })
        )
      }
    },
  }
}
