/// <reference path="./analytics.d.ts" />

import createAnalytics, { AnalyticsInstance, AnalyticsPlugin } from 'analytics'
import originalSrc from 'analytics-plugin-original-source'
import { appConfig } from 'config'
import { Platform } from 'react-native'
import { mixpanelBrowserPlugin } from '~/Services/Analytics/plugins/mixpanel-browser'
import { setUser } from '~/universal/sentry'
import { eventValidationPlugin } from './plugins/event-validation'
import { sentryPlugin } from './plugins/sentry-plugin'
import type * as PIdentify from './purposity-config/identify'
import type * as PPage from './purposity-config/pages'
import * as PTrack from './purposity-config/track'

export type MyAnalyticsInstance = AnalyticsInstance<
  PIdentify.Traits,
  PTrack.EventMap,
  PTrack.Options,
  PPage.PageDataCustom,
  PPage.Options,
  PIdentify.Options
>

const plugins: AnalyticsPlugin[] = []
const debug = process.env.NODE_ENV !== 'production'

if (Platform.OS === 'web') {
  plugins.push(originalSrc())
}

plugins.push(
  eventValidationPlugin({
    eventMap: PTrack.EventMap,
    debug,
  }),
  sentryPlugin({
    debug,
    setUser,
    formatUser: (identifyPayload) => {
      if (identifyPayload.userId) {
        const email = identifyPayload.traits.primary_email_address || undefined
        const username = identifyPayload.traits.clerk_id || undefined

        return {
          id: identifyPayload.userId || identifyPayload.anonymousId,
          ...(email && { email }),
          ...(username && { username }),
        }
      } else {
        return null
      }
    },
  })
)

if (Platform.OS === 'web') {
  plugins.push(
    mixpanelBrowserPlugin({
      debug,
      token: appConfig.mixpanel,
    })
  )
}

export const analytics = createAnalytics<MyAnalyticsInstance>({
  app: 'purposity',
  version:
    typeof __NEXT_DATA__ !== 'undefined' ? __NEXT_DATA__.buildId : undefined,
  debug,
  plugins,
})
