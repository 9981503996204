'use client'

import { useClerk, useSession, useUser } from '@clerk/nextjs'
import { UserResource } from '@clerk/types'
import * as React from 'react'

export const AuthContext = React.createContext({
  isLoaded: false as boolean | undefined,
  isSignedIn: false as boolean | undefined,
  user: {} as UserResource,
  clientId: '',
})

export function AuthProvider(props: React.PropsWithChildren<{ user?: any }>) {
  const { client } = useClerk()
  const { session } = useSession()
  const { isLoaded, isSignedIn, user } = useUser()
  const [_user, setUser] = React.useState(props.user || ({} as UserResource))
  const [_isSignedIn, setIsSignedIn] = React.useState(
    props.user?.userId != null
  )
  const [_isLoaded, setIsLoaded] = React.useState(props.user?.userId != null)
  const [_clientId, setClientId] = React.useState('')

  React.useEffect(() => {
    if (isLoaded && _isSignedIn && session == null) window.location.reload()
  }, [isLoaded, _isSignedIn, session])

  React.useEffect(() => {
    if (!isLoaded) return

    setIsLoaded(true)
    setClientId(client.id!)
    if (isSignedIn && !user.id) {
      setUser(user)
    }
    setIsSignedIn(isSignedIn)
  }, [isLoaded, isSignedIn])

  const value = {
    isLoaded: _isLoaded,
    isSignedIn: _isSignedIn,
    user: _user,
    clientId: props.user?.userId,
  }

  return (
    <AuthContext.Provider value={{ ...value }}>
      {props.children}
    </AuthContext.Provider>
  )
}

export const useAuthContext = () => {
  const context = React.useContext(AuthContext)

  if (!context) {
    throw new Error('useAuth must be used within a Auth Provider')
  }

  return context
}
