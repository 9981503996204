import { ValidId } from '@purposity/utils'
import * as z from 'zod'

const teamJoin = z.object({
  id: ValidId,
})

const teamLeave = z.object({
  id: ValidId,
})

const teamInvite = z.object({
  id: ValidId,
})

const orgFollow = z.object({
  id: ValidId,
  orgName: z.string(),
})

const orgUnfollow = z.object({
  id: ValidId,
})

/** derived from CheckoutSession
 * @see packages/backend-sdk/src/features/transactions/types.ts
 * */
const transactionSchema = z.object({
  // line_items: z
  //   .custom<
  //     | Products.Tip.LineItem
  //     | Products.Need.LineItem
  //     | Products.Designated.LineItem
  //   >()
  //   .array(),
  // summary: z.object({
  //   count: z.number().int(),
  //   sum: z.number(),
  // }),
  user_id: z.string(),
  checkout_intent_id: z.string().optional(),
  checkout_session_id: z.string().optional(),
})

const checkoutCreate = transactionSchema.partial()

const checkoutComplete = transactionSchema.partial()

const checkoutCancel = z.object({
  user_id: z.string(),
  checkout_intent_id: z.string(),
})

const checkoutExpire = z.object({
  user_id: z.string(),
  checkout_session_id: z.string(),
})

const goalSet = z.object({
  //
})

const userSignup = z.object({
  user_id: z.string(),
})

const userLogin = z.object({
  user_id: z.string(),
})

const userLogout = z.object({
  user_id: z.string(),
})

const needShare = z.object({
  //
})

const onboardingStart = z.object({
  //
})

const onboardingComplete = z.object({
  test: z.literal('me').optional(),
})

const storyClick = z.object({
  storyTitle: z.string(),
})

const followingTabClick = z.object({
  // Empty object since we don't need any additional properties
})

const needClick = z.object({
  needId: ValidId,
  needTitle: z.string(),
  isUrgent: z.boolean(),
  liveNeed: z.boolean(),
})

const profileButtonClick = z.object({
  // Empty object since we don't need any additional properties
})

const pageView = z.object({
  url: z.string(),
})

export const EventMap = z.object({
  page_view: pageView,
  team_join: teamJoin,
  team_leave: teamLeave,
  team_invite: teamInvite,
  org_follow: orgFollow,
  org_unfollow: orgUnfollow,
  goal_set: goalSet,
  user_signup: userSignup,
  user_login: userLogin,
  user_logout: userLogout,
  checkout_create: checkoutCreate,
  checkout_complete: checkoutComplete,
  checkout_cancel: checkoutCancel,
  checkout_expire: checkoutExpire,
  need_share: needShare,
  onboarding_start: onboardingStart,
  onboarding_complete: onboardingComplete,
  story_view: storyClick,
  following_tab_click: followingTabClick,
  need_click: needClick,
  profile_click: profileButtonClick,
})
export type EventMap = z.infer<typeof EventMap>

export type Options = {
  eventId?: string
}
