import { looseUrlSchema } from '@purposity/utils'
import type { PurposityEnv } from 'purposity-env'

export class AppConfig {
  constructor(
    public purposityEnv: PurposityEnv,
    public config: {
      appName: string
      /** @source env.NEXT_PUBLIC_CDN_ORIGIN */
      cdnOrigin: string | URL
      /** @source env.NEXT_PUBLIC_API_ORIGIN */
      hasuraOrigin: string | URL
      /** @source env.NEXT_PUBLIC_CLERK_AUTH_DOMAIN */
      clerkDomain: string | URL
      superOrgId: number
      sanity: {
        /** @source env.NEXT_PUBLIC_SANITY_PROJECT_ID */
        projectId: string
        /** @source env.NEXT_PUBLIC_SANITY_DATASET */
        dataset: string
      }
      logflare: {
        /** @source env.NEXT_PUBLIC_SANITY_PROJECT_ID */
        apiKey: string
        /** @source env.NEXT_PUBLIC_SANITY_DATASET */
        sourceKey: string
      }
      tip: {
        percentages: number[]
        defaultIndex: number
      }
      ga: string[]
      fb: string[]
      port?: number | undefined
      /** @source env.NEXT_PUBLIC_MIXPANEL_TOKEN */
      mixpanel: string
    }
  ) {}

  get appName(): string {
    return this.config.appName
  }

  /**
   * @deprecated
   * @use {@link cdnURL}
   */
  get cdnUrlBase(): URL {
    return this.cdnURL
  }
  get cdnURL(): URL {
    return new URL(this.config.cdnOrigin)
  }

  /**
   * @deprecated
   * @use {@link hasuraOrigin}
   */
  get hasuraApiUrlBase(): URL {
    return this.hasuraOrigin
  }
  get hasuraOrigin(): URL {
    return new URL(this.config.hasuraOrigin)
  }

  /** The canonical URL for the current environment */
  get deploymentURL(): URL {
    switch (this.purposityEnv) {
      case 'production':
        return new URL('https://www.purposity.com')
      case 'preview':
        return new URL('https://gateway.purposity.app/?project=www')

      case 'development':
      case 'local':
      default:
        return new URL(`http://localhost:${this.config.port ?? 3000}`)
    }
  }

  /**
   * @deprecated
   * @use {@link computedApiURL}
   */
  get computedApiBase(): string {
    return this.computedApiURL.href
  }
  /**
   * Correct URL for connecting to the GraphQL API's origin
   * @description Returns the proxied (branded domain) origin on the client and the true origin URL on the edge/server
   * @example `https://www.purposity.com` // client
   * @example `https://maximum-albacore-89.hasura.app` // edge/server
   */
  get computedApiURL(): URL {
    const value =
      typeof navigator !== 'undefined' && navigator.product === 'ReactNative'
        ? this.hasuraOrigin.href
        : typeof window === 'undefined'
          ? this.hasuraOrigin.href
          : window.location.href

    return new URL(value)
  }

  /**
   * @deprecated
   * @use {@link apiWebsocketURL}
   */
  get wsEndpoint(): string {
    return new URL(this.apiWebsocketURL).href
  }
  get apiWebsocketURL(): URL {
    return new URL('/v1/graphql', 'wss://' + this.hasuraOrigin.host)
  }

  get clerkOrigin(): URL {
    return looseUrlSchema.parse(this.config.clerkDomain, {
      path: ['appConfig', 'clerkOrigin'],
    })
  }

  get SUPER_ORG_ID(): number {
    return this.config.superOrgId
  }

  get ga(): {
    primary: string
    alternate: string
  } {
    const [primary = 'G-', alternate = 'G-'] = this.config.ga
    return {
      primary,
      alternate,
    }
  }

  get fb(): {
    primary: string
  } {
    const [primary = 'FB-'] = this.config.fb
    return {
      primary,
    }
  }

  get mixpanel(): string {
    return this.config.mixpanel
  }

  get tip(): typeof this.config.tip & {
    defaultPercentage: number
  } {
    return {
      ...this.config.tip,
      defaultPercentage:
        this.config.tip.percentages[this.config.tip.defaultIndex] ?? 0,
    }
  }

  get logflare() {
    return this.config.logflare
  }

  get sanity() {
    return this.config.sanity
  }
}
