import { Log } from '@services/Logger'
import mixpanel from 'mixpanel-browser'
import { Platform } from 'react-native'
import type { MyAnalyticsInstance } from '..'
import type { PluginsCreator } from '../analytics.types.plugins'

export type PluginConfig = {
  token: string
}

export type CreateMixpanelBrowserPlugin = PluginsCreator.Create<
  PluginConfig,
  MyAnalyticsInstance
>
let IS_INITIALIZED = false

const logger = Log.extend('analytics:mixpanel-browser')

export const mixpanelBrowserPlugin: CreateMixpanelBrowserPlugin = (
  pluginConfig
) => {
  if (pluginConfig.debug) {
    Log.enable('analytics:mixpanel-browser')
  } else {
    Log.disable('analytics:mixpanel-browser')
  }
  return {
    name: 'mixpanel-browser',
    config: pluginConfig,
    initialize: () => {
      if (IS_INITIALIZED) return
      const token = pluginConfig.token
      if (!token) {
        logger.error('MixPanel token is not set in analytics plugin')
        return
      }
      logger.debug('Initializing MixPanel')
      mixpanel.init(token, {
        debug: pluginConfig.debug,
        ignore_dnt: true,
        persistence: 'localStorage',
        api_host: 'https://api.mixpanel.com',
        cross_subdomain_cookie: false,
      })
      IS_INITIALIZED = true
    },
    track: ({ payload }: { payload: { event: string; properties: any } }) => {
      try {
        const properties: Omit<typeof payload.properties, 'user_id'> & {
          distinct_id?: string
        } = payload.properties
        properties.distinct_id = payload.properties?.user_id ?? ''
        if (!properties.distinct_id) {
          delete properties.distinct_id
        }
        const event = {
          ...properties,
          platform: Platform.OS === 'web' ? 'web' : 'mobile',
          userAgent:
            typeof window !== 'undefined'
              ? window.navigator.userAgent
              : undefined,
        }
        logger.debug('Tracking event:', payload.event, event)
        mixpanel.track(payload.event, event)
      } catch (error) {
        logger.error('Failed to track event in analytics plugin:', error)
      }
    },
    page: (bucket) => {
      try {
        const { payload } = bucket
        logger.debug('Tracking page view:', payload.properties.url)
        mixpanel.track('page_view', {
          url: payload.properties.url,
        })
      } catch (error) {
        logger.error('Failed to track page view in analytics plugin:', error)
      }
    },
    identify: (bucket) => {
      try {
        const { payload } = bucket
        logger.debug('Identifying user:', payload.userId, payload.traits)
        if (payload.userId) {
          mixpanel.identify(payload.userId)
          if (payload.traits) {
            mixpanel.people.set({
              email: payload.traits.primary_email_address || '',
              firstName: payload.traits.first_name || null,
              lastName: payload.traits.last_name || null,
              lastSignInAt: new Date().toISOString(),
              lastActiveAt: new Date().toISOString(),
            })
          }
        }
      } catch (error) {
        logger.error('Failed to identify user in analytics plugin:', error)
      }
    },
    reset: () => {
      try {
        mixpanel.reset()
        logger.debug('Resetting MixPanel')
      } catch (error) {
        logger.error('Failed to reset analytics in plugin:', error)
      }
    },
  }
}
