import { trpc } from '@/utils/trpc'
import { Favicon } from '@components/Favicon'
import { GlobalSeo } from '@components/SEO/FallbackSeo'
import { DevTools } from '@features/dev-tools'
import { KEY } from '@features/dev-tools/constants'
import { useEnabled } from '@features/dev-tools/hooks/useEnabled'
import { FlagProvider } from '@features/flags/context'
import { RoadblockModal } from '@features/roadblock/components/RoadblockModal'
import { RoadblockProvider } from '@features/roadblock/hooks/useRoadblock'
import Notifications from '@features/updates/components/Toasts'
import * as ReactQueryService from '@lib/react-query'
import '@purposity/icons/styles.css'
import { createCloudflareImageUrl } from '@purposity/utils'
import { useAnalyticsPageTracking } from '@services/Analytics/useAnalyticsPageTracking'
import {
  QueryClientProvider,
  Hydrate as ReactQueryHydrate,
} from '@tanstack/react-query'
import { clerkAppearanceOptions } from '@themes/ClerkThemeOptions'
import { Analytics } from '@vercel/analytics/react'
import { appConfig } from 'config'
import { env } from 'env'
import { AppProps } from 'next/app'
import Head from 'next/head'
import 'raf/polyfill'
import * as React from 'react'
import 'setimmediate'
import { SolitoImageProvider } from 'solito/image'
import type { AppPropsWithLayout } from 'types/next'
import { FullScreenLayoutProvider } from '~/layouts/FullScreenLayout.context'
import { ClerkProvider } from '~/universal/clerk'
import '../custom-globals'
// ReactNativeNextScrollRestore.initialize()

type MainAppProps = { user: string }

function MainApp({
  Component,
  pageProps,
  router,
  user,
}: AppProps & MainAppProps & AppPropsWithLayout<{ dehydratedState?: any }>) {
  useAnalyticsPageTracking(router)
  const getLayout = Component.getLayout ?? ((page) => page)
  return (
    <ClerkProvider
      appearance={clerkAppearanceOptions}
      signInUrl={env.NEXT_PUBLIC_CLERK_SIGN_IN_URL}
      allowedRedirectOrigins={[
        'http://localhost:3020',
        'https://www.purposity.com',
        'https://admin-v3.purposity.com',
        'https://admin.staging.purposity.com',
      ]}
    >
      <SolitoImageProvider
        loader={({ quality, src, width }) => {
          return createCloudflareImageUrl(
            src,
            new URL(env.NEXT_PUBLIC_CDN_ORIGIN),
            {
              width,
              quality,
            }
          )
        }}
      >
        <InnerProvider>
          <ReactQueryHydrate state={pageProps.dehydratedState}>
            <FlagProvider>
              <AllConfiguredProvider>
                {getLayout(<Component {...pageProps} />)}
              </AllConfiguredProvider>
            </FlagProvider>
          </ReactQueryHydrate>
        </InnerProvider>
      </SolitoImageProvider>
    </ClerkProvider>
  )
}

function InnerProvider(props: { children: React.ReactNode }) {
  const [queryClient] = React.useState(() => ReactQueryService.queryClient)

  ReactQueryService.useQueryClientPersist(queryClient)

  return (
    <QueryClientProvider client={queryClient}>
      <FullScreenLayoutProvider>
        <RoadblockProvider>
          <>{props.children}</>

          <Analytics />
          <Favicon />

          <Head>
            <meta
              name="viewport"
              content="width=device-width, initial-scale=1"
            />
            <GlobalSeo />
            <link
              key="cdn-preconnect"
              rel="preconnect"
              href={appConfig.cdnURL.href}
            />
            <link
              key="cdn-prefetch"
              rel="dns-prefetch"
              href={appConfig.cdnURL.href}
            />
            <link
              key="api-preconnect"
              rel="preconnect"
              href={appConfig.hasuraOrigin.href}
            />
            <link
              key="api-prefetch"
              rel="dns-prefetch"
              href={appConfig.hasuraOrigin.href}
            />
            <link
              key="auth-preconnect"
              rel="preconnect"
              href={appConfig.clerkOrigin.href}
            />
            <link
              key="auth-prefetch"
              rel="dns-prefetch"
              href={appConfig.clerkOrigin.href}
            />
          </Head>

          <Notifications />
          <RoadblockModal />
        </RoadblockProvider>
      </FullScreenLayoutProvider>
    </QueryClientProvider>
  )
}

export default trpc.withTRPC(MainApp)

function AllConfiguredProvider(props: React.PropsWithChildren) {
  const enabled = useEnabled(KEY)

  return (
    <>
      <>{props.children}</>
      {enabled && <DevTools />}
    </>
  )
}
